import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Image, Text } from "../Core"

class HeadingBlurbMedia extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let headingLevel

    if (this.props.headingLevel) {
      headingLevel = this.props.headingLevel
    } else {
      headingLevel = "h3"
    }

    return (
      <section className="section heading-blurb-video">
        <div className="columns">
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
          <div className="column">
            {this.props.heading && (
              <Text
                as={headingLevel}
                text={this.props.heading}
                className={this.props.headingLook}
                style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
              />
            )}
          </div>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
        </div>
        <div className="columns">
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
          <div className="column">
            <Image
              publicId={this.props.youtubePhotoPublicId}
              responsiveUseBreakpoints
            />
            {/* <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls
              playing={true}
              overVideo={false}
              hasWatchVideo={true}
              buttonClass="contained"
            >
            </TheaterVideo> */}
          </div>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
        </div>

        <div className="columns" style={{ marginTop: "1.5rem" }}>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
          {this.props.paragraph && (
            <div className="column">
              <Text
                className="mb-2--mobile small"
                text={this.props.paragraph}
              />
            </div>
          )}
          <div className="column is-1"></div>
          <div className="column is-narrow">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls
              playing
              hasWatchVideo
              buttonClass="contained mt-0 mx-auto--mobile"
            />
          </div>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
        </div>
      </section>
    )
  }
}

HeadingBlurbMedia.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string
}

export default HeadingBlurbMedia
